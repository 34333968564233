import React from "react"
import { Link } from "gatsby"

import { Row, Col, Carousel, Container, ListGroup } from "react-bootstrap"
import { ArrowDownCircle } from "react-bootstrap-icons"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Banner = ({}) => {
  return (
    <>
      <div
       id="home" 
        style={{
          padding: "10px",
          backgroundImage: `url(${"../../background.png"})`,
          backgroundRepeat: "repeat",
        }}
      >
        
          <Carousel  style={{
          width: "100%"}}>
       
           
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="../../Oil_Gas.jpg"
                alt="Second slide"
                style={{
                maxHeight:"650px",
                minHeight:"300px"}}
              />

              <Carousel.Caption>
                <div className="caption-overlay">

                  <Row>
                      <Col></Col>
                      <Col>
                        <div className="caption-text">
                          Oil &amp; Gas
                        </div>
                      </Col>
                      <Col ></Col>
                  </Row>  
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="../../Industrial.jpg"
                alt="Second slide"
                style={{
                  maxHeight:"650px",
                  minHeight:"300px"}}
                  />

              <Carousel.Caption>
                <div className="caption-overlay">
                  <Row>
                      <Col></Col>
                      <Col>
                        <div className="caption-text">
                          Industrial                        
                        </div>
                      </Col>
                      <Col ></Col>
                  </Row>  
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="../../Offshore.jpg"
                alt="Offshore"
                style={{
                  maxHeight:"650px",
                  minHeight:"300px"}}
                  />

              <Carousel.Caption>
                <div className="caption-overlay">
                  <Row>
                        <Col></Col>
                        <Col>
                          <div className="caption-text">
                            Offshore
                          </div>
                        </Col>
                        <Col ></Col>
                  </Row> 
                </div>
              </Carousel.Caption>
            </Carousel.Item>


            <Carousel.Item>
              <img
                className="d-block w-100"
                src="../../Marine.jpg"
                alt="Second slide"
                style={{
                  maxHeight:"650px",
                  minHeight:"300px"}}
                  />

              <Carousel.Caption>
                <div className="caption-overlay">
                  <Row>
                        <Col></Col>
                        <Col>
                          <div className="caption-text">
                          Marine
                          </div>
                        </Col>
                        <Col ></Col>
                  </Row> 
                
                </div>
              </Carousel.Caption>
            </Carousel.Item>
           
          </Carousel>
      </div>
     
    </>
  )
}

export default Banner
