import React,{useState} from "react"
import { Row, Col, Container, Alert,Toast, Button, Form } from "react-bootstrap"
import VisibilitySensor from "react-visibility-sensor"
import { Spring } from "react-spring/renderprops"
import { Parallax, Background } from "react-parallax"

import Banner from "../components/banner"
import scrollTo from "gatsby-plugin-smoothscroll"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "./about"
import IndustrialStandards from "./industries"
import BackgroundImage from "gatsby-background-image"
import Services from "./services"
import LegalStandards from "./associates"
import Team from "./team"
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from "../components/navBar"
import Section from "../components/Section/Section.component"
import {Geo,Telephone,Envelope, Check} from "react-bootstrap-icons"
import axios from "axios"

const h2Styles = {
  fontSize: "82px",
}
// styles
const centeredStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
}

const content = "We offer a range of services including warehousing, logistics, assembly, packaging and light manufacturing. Our extensive scope and flexibility means that we can value-add for our customers at every stage in the supply chain process. As a commercial division of HELP, we are also a proud disability friendly workplace, with a 90 percent supported employee ratio.";



const IndexPage = () => {

  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [company,setCompany] = useState("")
  const [message,setMessage] = useState("")


  const [inValidName,setInValidName] = useState(false)
  const [inValidEmail,setInValidEmail] = useState(false)
  const [inValidMessage,setInValidMessage] = useState(false)
  const [showToast,setShowToast] = useState(false)



  const recaptchaRef = React.createRef();



  const onSubmit = () => {
    console.log("On Submit")
    const recaptchaValue = recaptchaRef.current.getValue();
    console.log(recaptchaValue);

    if(recaptchaValue){
        console.log("Name = " + name);

        if(name.length === 0){
            setInValidName(true)
            return;
        }else{
          setInValidName(false)
        }
    
        console.log("Email = " + email);
    
        if(email.length === 0){
          setInValidEmail(true)
          return;
        }else{
          setInValidEmail(false)
        }
    
        console.log("message = " + message);
        if(message.length === 0){
          setInValidMessage(true)
          return;
        }else{
          setInValidMessage(false)
        }
        
        submitForm();
    }

  }

  function onChange(value) {
    console.log("Captcha value:", value);
   
  }

  const submitForm = () => {
    
    var bodyFormData = new FormData();
    bodyFormData.append('Name', name);
    bodyFormData.append('Company', company);
    bodyFormData.append('Email', email);
    bodyFormData.append('Message', message);

    console.log(bodyFormData)
    axios({
      method: 'post',
      url: 'https://formspree.io/f/xnqobqkv',
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
          //handle success
          console.log(response);
          setShowToast(true)
          setName("")
          setEmail("")
          setMessage("")
          setCompany("")
      })
      .catch(function (response) {
          //handle error
          console.log(response);
      });
  }

  return(
    <>
      <Navbar
            siteTitle={""}
            pageInfo={""}
          />

       <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
      
      
      <Banner/>

    
        <div  id="about-us" style={{ padding: "20px" }}>
          <Container>
          <About />
          </Container>
        </div>

        <div id="services" className="background_1" style={{ padding: "10px" }}>
        <Container style={{paddingLeft:"5px",paddingRight:"5px"}}>
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <>  
              
              <Spring
                delay={300}
                to={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? "translateX(0)" : "translateX(200px)",
                }}
              >
                {props => (
                  <Row style={{ ...props }}>
                    <Services />
                  
                  </Row>
                )}
                
              </Spring>
              </>
            )}
          </VisibilitySensor>
        </Container>

      </div>

      <br/>
      <br/>

  {/*
      <div id="industries" style={{ padding: "10px" }}>
        <Container>
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <>
              
                <Spring
                  delay={300}
                  to={{
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible ? "translateX(0px)" : "translateX(-200px)",
                  }}
                >
                
                  {props => (
                    <Row style={{ ...props }}>
                      <IndustrialStandards/>
                    </Row>
                  )}
                </Spring>

              </>
            )}
          </VisibilitySensor>

          <br/>
          <br/>           
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <>
              
                <Spring
                  delay={300}
                  to={{
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible ? "translateX(0px)" : "translateX(200px)",
                  }}
                >
                
                  {props => (
                    <Row style={{ ...props }}>
                      <Section handleButton={() => {}} content={content}  image={"../../banner_1.jpg"}/>

                    </Row>
                  )}
                </Spring>

              </>
            )}
          </VisibilitySensor>
        </Container>
      </div>            
                  
      <div id="associates" className="background_2" style={{overflow:"hidden",marginBottom: "-10px"}}>
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <Spring
                delay={300}
                to={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? "translateX(0)" : "translateX(200px)",
                }}
              >
                {props => (
                  <Row style={{ ...props }}>
                  
                    <LegalStandards/>
                  </Row>
                )}
              </Spring>
            )}
          </VisibilitySensor>
      </div>            
  */}

      <div style={{ padding: "10px" }}>
        <Team />   

      </div>           
    
    
      <div  id="contact-us"  className="background_3" >
          <div className="text-overlay">    
          <Container style={{textAlign:"center"}} ><h1 >Contact Us</h1></Container>

          <Container   >

         
          <VisibilitySensor partialVisibility offset={{ bottom: -400 }}>
            {({ isVisible }) => (
              <Spring
                delay={300}
                to={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? "translateY(0)" : "translateY(400px)",
                }}
              >
                {props => (
                  <Row style={{ ...props,     textAlign: "start" }}>
                    <Col>
                    <div  style={{minWidth:"250px", alignItems:"center", paddingTop:"90px", height:"100%"}}>

                    <Row>
                      <span style={{padding:"5px"}}><strong>AIDIA</strong></span><span style={{padding:"5px"}}> INDUSTRIAL Pty Ltd.</span>
                    </Row>
                    <Row><span style={{padding:"5px"}}><strong>ABN :</strong></span><span style={{padding:"5px"}}>34 645 670 391</span></Row>
                    <br/>
                      <Row >
                        <span><Geo color="gold"/></span>
                        <Col>
                          Suite 96, Level 9, 440 Collin St<br/>
                          Melbourne VIC 3000<br/>
                          Australia
                        </Col>
                      </Row>
                      <br/>
                      <Row >
                        <span><Geo color="gold"/></span>
                        <Col>
                          Ground Floor, 470 St Kilda Road<br/>
                          Melbourne VIC 3004<br/>
                          Australia
                        </Col>
                      </Row>
                      <br/>
                      <Row>
                        <span><Telephone color="gold"/></span>
                        <Col> +61 431 388 663</Col>
                      </Row>
                    <br/>
                    <Row>
                      <span><Envelope color="gold"/></span>
                      <Col>info@aidiaindustrial.com.au</Col>
                    </Row>
                    </div>
                    </Col>
                  
                    <Col>
                      <div  style={{minWidth:"250px"}}>
                      <Toast style={{width:"100%", maxWidth:"100%"}} onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                            <Alert style={{marginBottom: 0}} variant={"success"}>
                              <Check size={30}/>AIDIA Industrial received your message and we will get to you soon. 
                            </Alert>
                        </Toast> 
                      <Form >

                        <Form.Group controlId="formName">
                          <Form.Label>Name</Form.Label>
                          <Form.Control value={name} isInvalid={inValidName} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter name" required />
                          
                        </Form.Group>
                        <Form.Group controlId="formCompnay">
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control  value={company} onChange={(e) => setCompany(e.target.value)} type="text" placeholder="Enter Company name" />
                          
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control value={email} isInvalid={inValidEmail}  onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" required/>
                        
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Message</Form.Label>
                          <Form.Control value={message} isInvalid={inValidMessage}  onChange={(e) => setMessage(e.target.value)} as="textarea" rows={11} placeholder="" />
                         </Form.Group>
                      
                        <Row>
                          <Col>
                          <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey="6LdIVeEZAAAAAGv2GlfOSFgmwUqA9y3cBAD7uM9J"
                          onChange={onChange}
                        />
                          </Col>

                          <Col>
                          <Button variant="primary" style={{height:"100%", width:"100%"}} onClick={onSubmit}>
                          Submit
                        </Button>
                        
                          </Col>
                        </Row>

                        
                      
                      
                      </Form>
                    </div>
                    </Col>
                  </Row>
                )}
              </Spring>
            )}
          </VisibilitySensor>
      </Container>
      </div>      
        </div>
        
    </Layout>
    </>

  );
}

export default IndexPage
